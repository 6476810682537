import React from "react";
import { Helmet } from "react-helmet";

import Layout from "./components/Layout";
import Header from "./components/Header";

import styles from "./styles.module.less";
import ModuleList from "../ModuleList";
import withSurvey from "../withSurvey";

const ucLoader =
    typeof window !== "undefined" ? require("../../lib/ucLoader") : null;

const GivingToHeroesDetail = ({
    firestoreId,
    title = null,
    cobrandUrl = null,
    realtor = null,
    contacts = [],
    contact = null,
    branches = [],
    leadEmailInfo = [],
    isTeam = false,
    headBeginScript = [],
    headEndScript = [],
    bodyBeginScript = [],
    bodyEndScript = [],
    modules = [],
    applyNow,
    applyNowScript,
    twitter,
    contactInfo,
    isPreview,
    blogs,
    corporateBlogs,
    photo,
    testimonials: review = [],
    isSurveyInit: isReviewInit,
    hasSurveyErrored: hasReviewErrored,
    blogWord,
    templateKey,
    owner,
    logIn,
    slug,
    ownerSlug,
    socialIcons,
    licenseIds,
    jobTitle,
    team,
    stars,
    ratingsCount,
    shouldDisplaySurvey,
    testimonials,
    isSurveyInit,
    hasSurveyErrored,
    gallery,
    branch,
    profile,
    name,
    hero,
    licenseLink,
}) => {
    let aboutG2HModule = modules.find(({ type }) => type === "aboutG2H");
    let aboutBEModule = modules.find(({ type }) => type === "aboutBE");
    let calcModule = modules.find(({ type }) => type === "g2hCalculator");
    let testimonialsModule = modules.find(
        ({ type }) => type === "customTestimonials",
    );

    const header = (
        <Header
            cobrandUrl={cobrandUrl}
            title={title}
            hero={hero}
            showLinkAboutTheProgram={
                aboutG2HModule !== undefined ? !!aboutG2HModule.display : false
            }
            showLinkPotentialSavings={
                calcModule !== undefined ? !!calcModule.display : false
            }
            showLinkAboutBayEquity={
                aboutBEModule !== undefined ? !!aboutBEModule.display : false
            }
            showLinkTestimonials={
                testimonialsModule !== undefined
                    ? !!testimonialsModule.display
                    : false
            }
        />
    );

    const moduleListProps = {
        firestoreId,
        modules,
        applyNow,
        applyNowScript,
        twitter: !!socialIcons ? socialIcons.twitter : "",
        contactInfo,
        isPreview,
        blogs,
        corporateBlogs,
        ownerImg: photo,
        title,
        review,
        isReviewInit,
        hasReviewErrored,
        blogWord,
        templateKey,
        owner,
        logIn,
        slug,
        ownerSlug,
        socialIcons,
        licenseIds,
        name: !!contactInfo ? contactInfo.name : "",
        jobTitle,
        email: !!contactInfo ? contactInfo.email : "",
        phone: !!contactInfo ? contactInfo.phone : "",
        mobilePhone: !!contactInfo ? contactInfo.mobilePhone : "",
        fax: !!contactInfo ? contactInfo.fax : "",
        team,
        stars,
        ratingsCount,
        shouldDisplaySurvey,
        testimonials,
        isSurveyInit,
        hasSurveyErrored,
        gallery,
        branch,
        profile,
        pageTitle: title,
        pageUrl: "",
        leadEmailInfo,
        branches,
        realtor,
        pageType: "giving-to-heroes",
        licenseLink,
    };

    if (!!team) {
        let teamLoanOfficers = team.loanOfficers.map(lo => {
            const emptyLo = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            if (lo) {
                try {
                    const {
                        slug = "",
                        gallery,
                        profile: {
                            name = "",
                            email = "",
                            jobTitle = "",
                            licenseIds = [],
                        },
                    } = lo;

                    const photo = gallery && gallery.photo ? gallery.photo : "";

                    return {
                        slug,
                        photo,
                        name,
                        email,
                        jobTitle,
                        licenseIds,
                    };
                } catch (e) {
                    console.log(
                        "GivingToHeroesDetail team.loanOfficers map error for team" +
                            team.title +
                            ":  " +
                            e,
                    );
                    return emptyLo;
                }
            } else {
                console.log(
                    "GivingToHeroesDetail team.loanOfficers has null item for team " +
                        team.title,
                );
                return;
            }
        });
        moduleListProps.loanOfficers = teamLoanOfficers;
        moduleListProps.managers = team.managers;
        moduleListProps.staff = team.staff;
    } else if (!!branch) {
        let branchLoanOfficers = !!branch.loanOfficers
            ? branch.loanOfficers
                  .filter(lo => !!lo && !!lo.profile && !lo.hidden)
                  .map(lo => {
                      if (lo) {
                          try {
                              const {
                                  slug = "",
                                  gallery,
                                  profile: {
                                      name = "",
                                      email = "",
                                      jobTitle = "",
                                      licenseIds = [],
                                  },
                              } = lo;

                              const photo =
                                  gallery && gallery.photo ? gallery.photo : "";

                              return {
                                  slug,
                                  photo,
                                  name,
                                  email,
                                  jobTitle,
                                  licenseIds,
                              };
                          } catch (e) {
                              console.log(
                                  "GivingToHeroesDetail branch.loanOfficers map error for branch " +
                                      branch.title +
                                      ":  " +
                                      e,
                              );
                              return;
                          }
                      } else {
                          console.log(
                              "GivingToHeroesDetail branch.loanOfficers has null item for branch " +
                                  branch.title,
                          );
                          return;
                      }
                  })
            : [];

        let branchTeams = !!branch.teams
            ? branch.teams
                  .filter(t => !!t && !t.hidden)
                  .map(team => {
                      const emptyTeam = {
                          slug: "",
                          photo: "",
                          profile: {
                              name: "",
                              email: "",
                              jobTitle: "",
                              licenseIds: [],
                          },
                      };

                      try {
                          const { slug = "" } = team;

                          const licenseIds =
                              profile && profile.licenseIds
                                  ? profile.licenseIds
                                  : [];

                          return {
                              slug,
                              photo: "",
                              name: team.title,
                              email: team.profile.email,
                              jobTitle: "",
                              licenseIds: team.profile.licenseIds,
                          };
                      } catch (e) {
                          console.log("branch.teams map error:  " + e);
                          return emptyTeam;
                      }
                  })
            : [];

        let officers = branchLoanOfficers.concat(branchTeams);
        moduleListProps.loanOfficers = officers;
        moduleListProps.managers = branch.managers;
        moduleListProps.staff = branch.staff;
    }

    return (
        <Layout
            header={header}
            headBeginScript={headBeginScript}
            headEndScript={headEndScript}
            bodyBeginScript={bodyBeginScript}
            bodyEndScript={bodyEndScript}>
            <Helmet>
                <title>Bay Equity - Giving To Heroes</title>
                <meta
                    name="description"
                    content="Bay Equity - Giving To Heroes"
                />
                <meta name="keywords" content="Bay Equity Giving Heroes" />
                <meta name="viewport" content="width=device-width" />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
                <html lang="en" />
                <script dangerouslySetInnerHTML={{ __html: ucLoader }} />
            </Helmet>
            <div id="g2hpage" className={styles.g2hpage}>
                <div className={styles.moduleText}>
                    <ModuleList {...moduleListProps} />
                </div>
            </div>
        </Layout>
    );
};

export default withSurvey(GivingToHeroesDetail);
